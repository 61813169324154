import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './top-menu.scss';
import PropTypes from 'prop-types';
import SignInIcon from '../../util/icons/components/SignInIcon';
import { getGBIDomain } from '../../util/site';
import Signup from '../../user/signup/components/Signup';

class TopMenu extends Component {
  render () {
    return (
      <nav id='top-menu' onClick={this.props.onClick}>
        <ul className='top-level'>
          <li className='top-level'>
            <NavLink to='/about'
              className='bb-main-menu'
              activeClassName='active'>
              <span data-cy="main-nav-link-text-About">about</span>
            </NavLink>
          </li>
          <li className='top-level'>
            <NavLink to='/good-beef'
              className='bb-main-menu'
              activeClassName='active'>
              <span data-cy="main-nav-link-text-Good Beef">good beef</span>
            </NavLink>
          </li>
          <Signup buttonLabel='contact'/>
          <li className='top-level admin'>
            <a href={ `${getGBIDomain()}/account` }
              className='bb-main-menu'>
              <SignInIcon />
              my account
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

TopMenu.propTypes = {
  onClick: PropTypes.func
};

export default TopMenu;
