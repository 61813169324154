import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './breadcrumbs.scss';

const isMongoId = str => { // not a proper test
  try {
    if (str.length !== 24) return false;
    return !(isNaN(parseInt(str, 16)));
  } catch (error) {
    return false;
  }
};

const excludeUrlPart = part => {
  return (
    part === 'cattle' ||
    isMongoId(part) ||
    part === 'scorecard'
  );
};

// const segments = {
//   'suckler-beef': 'suckler beef',
//   'grass-fed': 'grass fed',
//   'nutritional-value': 'nutritional value',
//   'climate-change': 'climate change',
//   'good-beef': 'good beef',
//   'terms-and-conditions': 'terms and conditions',
//   'pricing-guidelines': 'pricing guidelines',
//   'advisory-panel': 'advisory panel',
//   'collateral-information': 'collateral information'
// };

const cleanSegment = segment => {
  /* eslint-disable no-useless-escape */
  return segment.replace(/\-/g, ' ');
  // if (segments[segment]) return segments[segment];
  // return segment;
};

class Breadcrumbs extends Component {
  renderCrumbs () {
    const { pathname } = this.props.location;
    if (!pathname) return null;
    const parts = pathname.split('/');
    let breadcrumbs = [
      (
        <li key='home'>
          <Link to={'/'}>
            <span data-cy="breadcrumb-home">home</span>
          </Link>
          <span className="spacer">|</span>
        </li>)
    ];
    let path = '';
    for (let i = 1; i < parts.length; i++) {
      const segment = parts[i];
      path += '/' + segment;
      const spacer = i < parts.length - 1 ? <span className="spacer">|</span>
        : null;
      if (excludeUrlPart(parts[i])) {
        continue;
      }
      const text = cleanSegment(segment);
      breadcrumbs.push(
        <li key={ path }>
          <Link to={ path }>
            <span data-cy={ `breadcrumb-${text}` }>{ text }</span>
          </Link>
          { spacer }
        </li>
      );
    }
    return breadcrumbs;
  }

  render () {
    return (
      <ul className='bb-breadcrumbs' data-cy="breadcrumbs">
        { this.renderCrumbs() }
      </ul>
    );
  }
}

Breadcrumbs.propTypes = {
  location: PropTypes.object.isRequired
};

export default Breadcrumbs;
