import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './main-nav.scss';
import PropTypes from 'prop-types';

class MainNav extends Component {
  render () {
    return (
      <nav id='main-nav' onClick={this.props.onClick}>
        <ul className='main-menu'>
          <li>
            <NavLink to='/cattle/young-bulls'
              className='menu-item'
              activeClassName='active'>
              <span className="bold">Bulls </span>
              <span className="light">for Breeding</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/cattle/cows'
              className='menu-item'
              activeClassName='active'>
              <span className="bold">Cows </span>
              <span className="light">for Breeding</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/cattle/heifers'
              className='menu-item'
              activeClassName='active'>
              <span className="bold">Heifers </span>
              <span className="light">for Breeding</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/cattle/finished-cattle'
              className='menu-item'
              activeClassName='active'>
              <span className="bold">Animals </span>
              <span className="light">for Beef</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  }
}

MainNav.propTypes = {
  onClick: PropTypes.func
};

export default MainNav;
