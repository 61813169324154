import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainNav from './MainNav';
import TopMenu from './TopMenu';
import Logo from './Logo';
import Breadcrumbs from './Breadcrumbs';
import './header.scss';
import CloseIcon from '../../util/icons/components/CloseIcon';
import { setMobileMenuOpen } from '../actions';
import { menuIsOpen } from '../reducer';

const mapDispatchToProps = dispatch => ({
  setMobileMenuOpen: open => dispatch(setMobileMenuOpen(open))
});

const mapStateToProps = state => ({
  menuOpen: menuIsOpen(state)
});

class Header extends Component {
  constructor (props) {
    super(props);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }
  toggleMobileMenu () {
    this.props.setMobileMenuOpen(!this.props.menuOpen);
  }
  render () {
    if (this.props.location.pathname === '/' ||
    this.props.location.pathname === '/login') return null;
    const BreadCrumbs = withRouter(props => <Breadcrumbs {...props}/>);
    const headerClass = this.props.menuOpen ? 'showing-menu' : null;
    return (
      <div id='header' className={headerClass}>
        <div className='top-section'>
          <div className='bb-page-wrapper'>
            <Link className="logo-wrapper" to="/">
              <Logo noLink isMain/>
            </Link>
            <MainNav onClick={ this.toggleMobileMenu } />
            <CloseIcon onClick={ this.toggleMobileMenu } />
            <div className='top-right'>
              <TopMenu onClick={ this.toggleMobileMenu } />
            </div>
            <div className='mobile-menu-icons'>
              <div
                data-cy="bb-header-burger"
                className='burger'
                onClick={this.toggleMobileMenu}>☰</div>
            </div>
          </div>
        </div>
        <div className='bottom-section'>
          <div className='bb-page-wrapper'>
            <BreadCrumbs />
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  menuOpen: PropTypes.bool,
  setMobileMenuOpen: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
